import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import {
    Button,
    Col,
    Form,
    Input,
    Modal,
    Row,
    Switch,
    Tabs,
    Timeline,
    message,
} from 'antd'

import moment from 'moment'

import {
    commentService,
    customerService,
    franchiseeService,
    simulationService,
} from '../../redux/services'

import { UIDrawerForm } from '../../components'
import { number_format } from '../../helpers/phpjs'

const config = {
    externalName: 'Commentário',
}

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`

class Comment extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        external: PropTypes.bool,
    }

    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            id: 0,
            items: [],
            comment: '',
            commentEdited: '',
            currentEdit: {
                index: null,
                comment: undefined,
            }
        }
    }

    onOpen = (id) => {
        this.setState({
            isLoading: true,
            id: id,
            items: [],
        })

        commentService
            .show({ id })
            .then((response) => {
                let items = response.data

                this.setState({
                    isLoading: false,
                    items: items,
                })
            })
            .catch((data) => {
                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                    onOk: () => {
                        // Force close
                        return this.onClose()
                    },
                })
            })
    }

    resetFields = () => {
        this.setState(
            {
                comment: '',
            },
            () => {
                this.form.resetFields()
            }
        )
    }

    onClose = () => {
        // Reset fields
        this.resetFields()

        // Callback
        this.props.onClose()
    }

    onFinish = (values) => {
        this.setState({
            isSending: true,
        })

        const data = {
            ...values,
            customer_id: this.state.id,
        }

        commentService
            .create(data)
            .then(() => {
                this.setState({
                    isSending: false,
                })

                // Reset fields
                this.resetFields()

                // Success message
                message.success('Registro cadastrado com sucesso.')

                // Callback
                this.props.onComplete()
            })
            .catch((data) => {
                this.setState({
                    isSending: false,
                })

                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    onChangeComment = (e) => {
        this.setState({
            comment: e.target.value,
        })
    }

    onChangeCommentEdited = (e) => {
        this.setState({
            commentEdited: e.target.value,
        })
    }


    onEditComment = (index, comment) => {
        if (this.state.currentEdit.index === index) {
            this.onSave()
        }

        this.setState({
            currentEdit: {
                index,
                comment,
            },
        })
    }

    onSave = (values) => {
        this.setState({
            isSending: true,
        })

        const data = {
            ...this.state.currentEdit?.comment,
            text: this.state.commentEdited,
        }

        commentService
            .edit(data)
            .then(() => {
                this.setState({
                    isSending: false,
                    currentEdit: {
                        index: null,
                        comment: undefined,
                    },
                    commentEdited: ""
                })

                // Reset fields
                this.resetFields()

                // Success message
                message.success('Registro atualizado com sucesso.')

                // Callback
                this.props.onComplete()
            })
            .catch((data) => {
                this.setState({
                    isSending: false,
                    currentEdit: {
                        index: null,
                        comment: undefined,
                    },
                    commentEdited: ""
                })

                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    render() {
        const { visible, userData } = this.props
        const { id, isLoading, items, comment, currentEdit } = this.state

        return (
            <UIDrawerForm
                visible={visible}
                width={300}
                onClose={this.onClose}
                isLoading={isLoading}
                showBtnSave={!!comment.length}
                formId={formId}
                title={`Visualizar ${this.props.external ? config.externalName : 'registro'
                    } [${id}]`}
            >
                <Form
                    ref={(el) => (this.form = el)}
                    id={formId}
                    layout="vertical"
                    scrollToFirstError
                    onFinish={this.onFinish}
                    initialValues={{
                        password_random: true,
                        is_active: true,
                    }}
                >
                    <Form.Item
                        name="text"
                        label="Comentário"
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Campo obrigatório.',
                            },
                        ]}
                    >
                        <Input.TextArea onChange={this.onChangeComment} />
                    </Form.Item>
                    <Timeline
                        style={{ marginTop: 30 }}
                        mode="left"
                        items={items.map((comment, index) => ({
                            label:
                                <div>
                                    {
                                        comment.user.name +
                                        ' - ' +
                                        moment(comment.created_at).calendar()
                                    }
                                    {userData.id === comment.user_id && <Button onClick={() => this.onEditComment(index, comment)} style={{ marginLeft: 10 }} type='primary' icon={<i className={currentEdit.index === index ? "fad fa-save" : "fal fa-pen"} />} size='small' />}
                                </div>,
                            children: currentEdit.index === index ? (
                                <Input.TextArea defaultValue={currentEdit.comment.text} onChange={this.onChangeCommentEdited} />
                            ) : (
                                <span
                                    style={{
                                        paddingBottom: 20,
                                        display: 'block',
                                    }}
                                    dangerouslySetInnerHTML={{ __html: comment.text }}
                                />
                            ),
                        }))}
                    />
                </Form>
            </UIDrawerForm>
        )
    }
}

export default Comment
