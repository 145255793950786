import React, { Component, Fragment } from 'react'
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar'

import { UILayout, UIPageListing } from '../../components'
import { Button, Dropdown, Menu, Modal, Spin } from 'antd'
import { connect } from 'react-redux'
import { generalActions } from '../../redux/actions'
import { orderService } from '../../redux/services'
import ModalCreate from './create'
import ModalShow from './show'
import ModalEdit from './edit'
import ModalFilters from './filters'
import ModalStage from './stage'
import { number_format } from 'helpers/phpjs'
import ModalComment from './comment'
import moment from 'moment'

const config = {
    title: 'Vendas',
    permissionPrefix: 'orders',
    list: 'orders',
    searchPlaceholder: 'Buscar por empresa ou cnpj',
    orders: [
        {
            label: 'Mais recentes',
            field: 'id',
            sort: 'desc',
            default: true,
        },
        {
            label: 'Mais antigos',
            field: 'id',
            sort: 'asc',
        },
    ],
}

class Orders extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
        }

        const defaultOrder = config.orders.find((o) => o.default)

        this.state = {
            isLoading: false,
            listType: 'list',
            data: [],
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0,
            },
            orderByLabel: defaultOrder.label,
            orderByField: defaultOrder.field,
            orderBySort: defaultOrder.sort,
            search: '',
            // Actions
            commentModalVisible: false,
            createModalVisible: false,
            showModalVisible: false,
            editModalVisible: false,
            stageModalVisible: false,
            exportModalVisible: false,
            filtersModalVisible: false,
            activeLoadings: [],
            isExporting: false,
            // Filters
            totalFilters: 0,
            filters: {
                signed: null,
                created_at: null,
                stage_id: this.props.router?.location?.state?.stage_id ?? null,
                user_id: null,
                service_id: null,
            },
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.listType && state.listType !== props.listType) {
            return {
                listType: props.listType,
            }
        }

        return null
    }

    componentDidMount() {
        this.filtersOnComplete({
            ...this.state.filters,
            stage_id: this.props.router?.location?.state?.stage_id ?? null,
            signed: this.props.router?.location?.state?.signed ?? null,
            created_at: this.props.router?.location?.state?.created_at ?? null,
            user_id: this.props.router?.location?.state?.user_id ?? null,
            service_id: this.props.router?.location?.state?.service_id ?? null,
        })
        // Fecth all
        // this.fetchGetAll(true)
    }

    menuItem = (item) => (
        <Menu className="actions-dropdown-menu">
            {item.stage_id === 3 && !item.signed && (
                <Menu.Item key="signed">
                    <a onClick={() => this.onSignOrder(item)}>
                        <i className="fal fa-check-square"></i>
                        Assinar
                    </a>
                </Menu.Item>
            )}
            <Menu.Item key="show">
                <a onClick={() => this.showOpen(item)}>
                    <i className="fal fa-file" />
                    Visualizar
                </a>
            </Menu.Item>
            <Menu.Item key="edit">
                <a onClick={() => this.editOpen(item)}>
                    <i className="fal fa-pen" />
                    Editar
                </a>
            </Menu.Item>
            <Menu.Item key="comment">
                <a onClick={() => this.commentOpen(item)}>
                    <i className="fal fa-comment"></i>Comentário
                </a>
            </Menu.Item>
            <Menu.Item key="stage" className="divider">
                <a onClick={() => this.stageOpen(item)}>
                    <i className="fal fa-walking"></i>
                    Etapa
                </a>
            </Menu.Item>
            {(this.props.userData.role === 'admin' || this.props.userData.role === "collaborator_admin") && (
                <Menu.Item key="delete" className="divider btn-delete">
                    <a onClick={() => this.deleteConfirm(item)}>
                        <i className="fal fa-trash" />
                        Excluir
                    </a>
                </Menu.Item>)
            }
        </Menu>
    )

    columns = () => {
        const listTypeCard = this.state.listType === 'card'

        return [
            // {
            //     title: 'ID',
            //     className: 'id',
            //     render: (item) => <span title={item.id}>{item.id}</span>,
            // },
            {
                title: 'Empresa',
                // width: 200,
                render: (item) => {
                    let content = ''
                    if (item.customer?.corporate_name) {
                        content = item.customer?.corporate_name
                    }

                    return content
                },
            },
            {
                title: 'CNPJ',
                width: 200,
                render: (item) => {
                    let content = ''
                    if (item.customer?.document) {
                        content = item.customer?.document
                    }

                    return content
                },
            },
            {
                title: 'Franqueado',
                width: 200,
                render: (item) => {
                    let content = ''
                    if (item.franchisee?.name) {
                        content = item.franchisee?.name
                    }

                    return content
                },
            },
            {
                title: 'Créd. estimado',
                width: 120,
                render: (item) => (
                    <span title={item.estimated_credit}>
                        R$ {number_format(item.estimated_credit, 2, ',', '.')}
                    </span>
                ),
            },
            {
                title: 'Créd. apurado',
                width: 120,
                render: (item) => (
                    <span title={item.credit}>
                        R$ {number_format(item.credit, 2, ',', '.')}
                    </span>
                ),
            },
            // {
            //     title: 'Honorário %',
            //     width: 100,
            //     render: (item) => (
            //         <span title={item.honorary}>
            //             {number_format(item.honorary, 2, ',', '.')}%
            //         </span>
            //     ),
            // },
            {
                title: 'Serviço',
                width: 100,
                render: (item) => {
                    let content = ''
                    if (item.service.name) {
                        content = item.service.name
                    }

                    return content
                },
            },
            {
                title: 'Etapa',
                width: 100,
                render: (item) => {
                    let content = ''
                    if (item.stage.name) {
                        content = item.stage.name
                    }

                    return content
                },
            },
            {
                title: 'Contrato',
                width: 60,
                className: 'active no-ellipsis',
                render: (item) =>
                    this.state.activeLoadings.indexOf(item.id) !== -1 ? (
                        <Spin
                            indicator={
                                <i className="fad fa-spinner-third fa-spin" />
                            }
                        />
                    ) : (
                        <>
                            {item.signed ? (
                                <i
                                    className="fal fa-check-circle"
                                    style={{ color: 'green' }}
                                ></i>
                            ) : (
                                <i
                                    className="fal fa-times-circle"
                                    style={{ color: 'red' }}
                                ></i>
                            )}
                        </>
                    ),
            },
            {
                title: 'Ações',
                className: 'actions no-ellipsis',
                // visible  : this.props.permissions.includes(config.permissionPrefix + ".show") || this.props.permissions.includes(config.permissionPrefix + ".edit") || this.props.permissions.includes(config.permissionPrefix + ".delete"),
                visible: true,
                render: (item) => (
                    <Dropdown
                        overlay={this.menuItem(item)}
                        className="actions-dropdown"
                        placement="bottomRight"
                        trigger={['click']}
                    >
                        <Button icon={<i className="fal fa-ellipsis-v" />} />
                    </Dropdown>
                ),
            },
        ]
    }

    fetchGetAll = (init = false, exportItems = false) => {
        const { pagination, orderByField, orderBySort, search, filters } =
            this.state

        const { userData } = this.props
        let user_id = this.props.router?.location?.state?.userId

        if (exportItems) {
            this.setState({
                isExporting: true,
            })
        } else {
            this.setState({
                isLoading: true,
            })
        }

        const data = {
            orderBy: `${orderByField}:${orderBySort}`,
            search: search,
        }



        if (userData.role !== "admin" && userData.role !== "collaborator_admin") {
            if (user_id) {
                data.user_id = user_id
            }
        } else {
            if (filters.user_id !== null) {
                data.user_id = filters.user_id
            }
        }


        if (exportItems) {
            data.exportItems = true
        } else {
            data.page = init ? 1 : pagination.current
            data.limit = pagination.pageSize
        }

        if (filters.signed !== null) {
            data.signed = filters.signed
        }

        if (filters.stage_id !== null) {
            data.stage_id = filters.stage_id
        }


        if (filters.service_id !== null) {
            data.service_id = filters.service_id
        }

        if (filters.created_at) {
            data.created_at = [
                filters.created_at[0]
                    .clone()
                    .startOf('day')
                    .format('YYYY-MM-DDTHH:mm:ssZ'),
                filters.created_at[1]
                    .clone()
                    .endOf('day')
                    .format('YYYY-MM-DDTHH:mm:ssZ'),
            ]
        }

        orderService
            .getAll(data)
            .then((response) => {
                if (exportItems) {
                    if (exportItems) {
                        this.setState({
                            isExporting: false,
                        })

                        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
                        // Cria um link temporário para download
                        const fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', `gestao_${moment().format("DD_mm_yyyy_hh_mm_ss")}.xlsx`);
                        document.body.appendChild(fileLink);

                        fileLink.click();

                        fileLink.parentNode.removeChild(fileLink);
                    }
                } else {
                    this.setState((state) => ({
                        isLoading: false,
                        data: response.data.data,
                        pagination: {
                            ...state.pagination,
                            current: response.data.meta.current_page,
                            total: response.data.meta.total,
                        },
                    }))
                }
            })
            .catch((data) => {
                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    onListTypeChange = (type) => {
        this.props.onChangeListType(type)
    }

    onPaginationChange = (page) => {
        this.setState(
            (state) => ({
                pagination: {
                    ...state.pagination,
                    current: page,
                },
            }),
            () => {
                this.fetchGetAll()
            }
        )
    }

    onOrderChange = (value) => {
        const defaultOrder = config.orders.find(
            (o) => `${o.field}:${o.sort}` === value
        )

        if (!defaultOrder) return null

        this.setState(
            (state) => ({
                orderByLabel: defaultOrder.label,
                orderByField: defaultOrder.field,
                orderBySort: defaultOrder.sort,
            }),
            () => {
                this.fetchGetAll(true)
            }
        )
    }

    onSearch = (value) => {
        this.setState(
            {
                client_name: value,
            },
            () => {
                this.fetchGetAll(true)
            }
        )
    }

    onSearchChange = (e) => {
        // If it does not have type then it's cleaning
        if (!e.hasOwnProperty('type')) {
            const { search } = this.state

            this.setState(
                {
                    search: e.target.value,
                },
                () => {
                    if (search) {
                        this.fetchGetAll(true)
                    }
                }
            )
        }
    }

    onSignOrder = ({ id }) => {
        const { activeLoadings } = this.state

        if (activeLoadings.indexOf(id) === -1) {
            activeLoadings.push(id)
        }

        this.setState({
            activeLoadings: activeLoadings,
        })

        orderService
            .edit({ id, signed: true })
            .then((response) => {
                const newData = [...this.state.data]
                const index = newData.findIndex((item) => id === item.id)

                if (index !== -1) {
                    const item = newData[index]

                    newData.splice(index, 1, {
                        ...item,
                        signed: true,
                    })

                    this.setState({
                        data: newData,
                    })
                }
            })
            .catch((data) => {
                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
            .finally(() => {
                const { activeLoadings } = this.state
                const index = activeLoadings.indexOf(id)

                if (index !== -1) {
                    activeLoadings.splice(index, 1)

                    this.setState({
                        activeLoadings: activeLoadings,
                    })
                }
            })
    }

    /**
     * Comment
     *
     * @param id
     */
    commentOpen = ({ customer_id }) => {
        this.setState({ commentModalVisible: true })

        // On open screen
        this.commentScreen.onOpen(customer_id)
    }

    commentOnClose = () => {
        this.setState({ commentModalVisible: false })
    }

    commentOnComplete = () => {
        this.setState({ commentModalVisible: false })

        // Fecth all
        this.fetchGetAll(true)
    }

    /**
     * Show
     *
     * @param id
     */
    showOpen = ({ id }) => {
        this.setState({ showModalVisible: true })

        // On open screen
        this.showScreen.onOpen(id)
    }

    showOnClose = () => {
        this.setState({ showModalVisible: false })
    }

    /**
     * Delete
     *
     * @param id
     */
    deleteConfirm = ({ id }) => {
        Modal.confirm({
            title: 'Confirmar exclusão!',
            content: 'Tem certeza de que deseja excluir este registro?',
            okText: 'Excluir',
            autoFocusButton: null,
            onOk: () => {
                return this.deleteConfirmed(id)
            },
        })
    }

    deleteConfirmed = (id) => {
        return orderService
            .destroy({ id })
            .then((response) => {
                // Fecth all
                this.fetchGetAll()
            })
            .catch((data) => {
                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    /**
     * Create
     */
    createOpen = () => {
        this.setState({ createModalVisible: true })

        // On open screen
        this.createScreen.onOpen()
    }

    createOnClose = () => {
        this.setState({ createModalVisible: false })
    }

    createOnComplete = () => {
        this.setState({ createModalVisible: false })

        // Fecth all
        this.fetchGetAll(true)
    }

    /**
     * Edit
     *
     * @param id
     */
    editOpen = ({ id }) => {
        this.setState({ editModalVisible: true })

        // On open screen
        this.editScreen.onOpen(id)
    }

    editOnClose = () => {
        this.setState({ editModalVisible: false })
    }

    editOnComplete = () => {
        this.setState({ editModalVisible: false })

        // Fecth all
        this.fetchGetAll()
    }

    /**
     * Stage
     *
     * @param id
     */
    stageOpen = (item) => {
        this.setState({ stageModalVisible: true })

        // On open screen
        this.stageScreen.onOpen(item)
    }

    stageOnClose = () => {
        this.setState({ stageModalVisible: false })
    }

    stageOnComplete = () => {
        this.setState({ stageModalVisible: false })

        // Fecth all
        this.fetchGetAll()
    }

    /**
     * Filter
     */
    filtersOpen = () => {
        this.setState({ filtersModalVisible: true })

        // On open screen
        this.filtersScreen.onOpen({ ...this.state.filters })
    }

    filtersOnClose = () => {
        this.setState({ filtersModalVisible: false })
    }

    filtersOnComplete = (filters) => {
        this.setState({ filtersModalVisible: false })

        this.setState(
            {
                totalFilters: Object.keys(filters).filter(
                    (key) =>
                        filters.hasOwnProperty(key) && filters[key] !== null
                ).length,
                filters: filters,
            },
            () => {
                // Fecth all
                this.fetchGetAll(true)
            }
        )
    }

    render() {
        const { data } = this.state

        return (
            <UILayout>
                <DashboardNavbar absolute isMini />
                <div className="page page-franchisees">
                    <UIPageListing
                        onSearch={this.onSearch}
                        onSearchChange={this.onSearchChange}
                        onPaginationChange={this.onPaginationChange}
                        onOrderChange={this.onOrderChange}
                        onListTypeChange={this.onListTypeChange}
                        onFiltersClick={this.filtersOpen}
                        isLoading={this.state.isLoading}
                        listType={this.state.listType}
                        orderByField={this.state.orderByField}
                        orderBySort={this.state.orderBySort}
                        orders={config.orders}
                        search={this.state.search}
                        searchPlaceholder={config.searchPlaceholder}
                        data={this.state.data}
                        pagination={this.state.pagination}
                        columns={this.columns()}
                        showFilters={true}
                        totalFilters={this.state.totalFilters}
                        totalResults={this.state.pagination?.total}
                        buttons={[
                            {
                                visible: true,
                                onClick: () => this.fetchGetAll(true, true),
                                title: this.state.isExporting ? "Exportando" : "Exportar",
                                icon: <i className="fal fa-file-export" />,
                                loading: this.state.isExporting,
                            },
                            {
                                visible: true,
                                onClick: this.createOpen,
                                title: 'Cadastrar',
                                icon: <i className="far fa-plus" />,
                            },
                        ]}
                    />
                </div>
                <ModalShow
                    ref={(el) => (this.showScreen = el)}
                    visible={this.state.showModalVisible}
                    onClose={this.showOnClose}
                />
                <ModalCreate
                    ref={(el) => (this.createScreen = el)}
                    visible={this.state.createModalVisible}
                    onComplete={this.createOnComplete}
                    onClose={this.createOnClose}
                    userData={this.props.userData}
                />
                <ModalFilters
                    ref={(el) => (this.filtersScreen = el)}
                    visible={this.state.filtersModalVisible}
                    onComplete={this.filtersOnComplete}
                    onClose={this.filtersOnClose}
                />
                <ModalEdit
                    ref={(el) => (this.editScreen = el)}
                    visible={this.state.editModalVisible}
                    onComplete={this.editOnComplete}
                    onClose={this.editOnClose}
                    userData={this.props.userData}
                />
                <ModalStage
                    ref={(el) => (this.stageScreen = el)}
                    visible={this.state.stageModalVisible}
                    onComplete={this.stageOnComplete}
                    onClose={this.stageOnClose}
                />
                <ModalComment
                    ref={(el) => (this.commentScreen = el)}
                    visible={this.state.commentModalVisible}
                    onComplete={this.commentOnComplete}
                    onClose={this.commentOnClose}
                    userData={this.props.userData}
                />
            </UILayout>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        permissions: state.auth.userData.permissions,
        listType: state.general.listType[config.list],
        userData: state.auth.userData,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onChangeListType: (type) => {
            dispatch(generalActions.changeListType(config.list, type))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Orders)
