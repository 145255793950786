import { api } from '../../config/api'

const basePath = 'comments'

/**
 * Create
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const create = (options) => {
    return api.post(`${basePath}`, options)
}

/**
 * UpdatEdite
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const edit = (options) => {
    return api.put(`${basePath}/${options.id}`, options)
}


/**
 * Show
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const show = (options) => {
    return api.get(`${basePath}/${options.id}`)
}
